<template>
    <div>
        <van-popup class="popclass" closeable v-model="show">
            <div class="content">
                <div class="title">申述问题：</div>
                <div class="remark">{{ remarkInfo1.reason }}</div>
                <div class="title">申述结果：</div>
                <div class="remark">{{ remarkInfo1.judgment_remark }}</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import {viewAppeal} from "@/request/api";
    import {Toast} from "vant";

    export default {
        data() {
            return {
                show: false,
                oid: "",
                userInfo: {},
                remarkInfo1: "",
                password: "",
                address: "",
            };
        },
        props: {
            value: String,
        },
        methods: {
            preview(item) {
                this.userInfo = sessionStorage.getItem("user")
                    ? JSON.parse(sessionStorage.getItem("user"))
                    : {};
                this.show = true;
                this.address = sessionStorage.getItem("address");
                this.remarkInfo1 = item;
                console.log(this.remarkInfo1)
            },

        },
    };
</script>

<style scoped lang="scss">
    .content {
        padding: 32px;

        .title {
            color: rgba(139, 166, 149, 1);
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
            margin-bottom: 18px;
            text-align: center;
        }
        .remark {
            min-width: 350px;
            min-height: 300px;
        }
    }
</style>
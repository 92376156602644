<template>
  <div>
    <van-action-sheet v-model="showUpload" title="上传截图" class="modalclass">
      <div class="screen">
        <div class="imgcontent">
          <div class="uploadcontent">
            <van-uploader :max-size="5000 * 1024" :after-read="clickUpload">
              <img
                v-if="imgurl.length == 0"
                class="uploadicon"
                :src="require('@/assets/images/mine/uploadimg.png')"
              />
              <img v-else class="uploadicon" :src="imgurl" />
            </van-uploader>
            <div class="txt">请上传您的截图</div>
          </div>
          <div class="surebtn margin48">
            <div class="btn" @click="showpwd1 = true">确认支付</div>
          </div>
          <div class="tip">
            注意 <br />
            1.请先支付后保留支付凭证 <br />
            2.上传凭证等待确认
          </div>
        </div>
      </div>
    </van-action-sheet>
      <van-dialog
              v-model="showpwd1"
              title="请输入安全码"
              @cancel="showpwd1 = false"
              @confirm="clickPay"
              show-cancel-button
      >
        <van-field
                type="password"
                v-model="pay_code"
                name="安全密码"
                label="安全密码"
                placeholder="请输入安全密码"
        ></van-field>
      </van-dialog>
  </div>
</template>

<script>
import { uploadImg, paymentSubmit } from "@/request/api";
import { Toast } from "vant";
export default {
  components: {},
  data() {
    return {
      imgurl: "",
      showUpload: false,
      oid: "",
      userInfo: {},
      pay_code: "",
      address: "",
        showpwd1:false,
        applyOrder:{},
    };
  },
  methods: {
    showScreen(item) {
      this.showUpload = true;
      this.imgurl = "";
      this.applyOrder = item;
      this.userInfo = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : {};
      this.address = sessionStorage.getItem("address");
      this.oid = item.id;
    },
    clickUpload(file) {
      const fd = new FormData();
      fd.append("file", file.file);
      uploadImg(fd).then((res) => {
          if(res.code==200){
              this.imgurl = res.data.http_url;
          }else{
              Toast(res.msg)
          }
        // this.uploadScreen();
      });
    },
    clickPay() {
      if (this.imgurl.length === 0) {
        Toast("请上传截图");
        return;
      }
        if (this.pay_code.length === 0) {
            Toast("请输入安全码");
            return;
        }
      this.uploadScreen();
    },
    uploadScreen() {
      const params = {
        id: this.oid,
        address: this.address,
        images_url: this.imgurl,
        pay_code: this.pay_code,
      };
      paymentSubmit(params).then((res) => {
          this.pay_code='';
        if (res.code==200) {
          Toast("上传成功");
          this.$emit("refreshList");
            this.showUpload = false;
        } else {
          this.imgurl = "";
          Toast(res.msg);
            this.showUpload = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.margin48 {
  margin-top: 48px;
}
.modalclass {
  background: #202823;
  border-radius: 12px 12px 0px 0px;
  color: #f5fff9;
  .screen {
    padding: 28px 28px 320px;
    display: flex;
    justify-content: space-around;
  }
  .imgcontent {
    width: 694px;
    height: 244px;
    border-radius: 16px;
    opacity: 1;
    background: rgba(41, 51, 45, 1);
    .uploadcontent {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .uploadicon {
        width: 150px;
        height: 150px;
      }
      .txt {
        opacity: 1;
        color: rgba(139, 166, 149, 1);
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 36px;
      }
    }
    .surebtn {
      display: flex;
      justify-content: center;
      .btn {
        width: 320px;
        height: 88px;
        border-radius: 16px;
        opacity: 1;
        background: rgba(39, 204, 127, 1);
        color: rgba(32, 40, 35, 1);
        font-size: 36px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 88px;
      }
    }
  }
  .tip {
    margin-top: 32px;
    opacity: 1;
    color: rgba(182, 217, 195, 1);
    font-size: 24px;
    font-weight: 700;
    line-height: 44px;
  }
}
</style>
<template>
    <div class="bgcontent">
        <Header style="background: none">
            <template slot="title">订单记录</template>
        </Header>
        <div class="order">
            <div class="menubtn">
                <div class="btn">
                    <div
                            :class="type === 1 ? 'getbtn' : 'consumptionbtn'"
                            @click="changeMenu(1)"
                    >
                        买入
                    </div>
                    <div
                            :class="type === 2 ? 'getbtn' : 'consumptionbtn'"
                            @click="changeMenu(2)"
                    >
                        卖出
                    </div>
                </div>
            </div>
            <div class="buyandsell margin36">
                <van-tabs
                        class="tab"
                        v-model="activeTab"
                        @change="changeTab"
                        title-active-color="#27CC7F"
                        title-inactive-color="#8BA695"
                        background="#0C1913"
                        color="#27CC7F"
                >
                    <van-tab @change="changeTab" title="全部"></van-tab>
                    <van-tab @change="changeTab" title="待支付"></van-tab>
                    <van-tab @change="changeTab" title="已付款"></van-tab>
                    <van-tab @change="changeTab" title="已完成"></van-tab>
                    <van-tab @change="changeTab" title="申诉中"></van-tab>
                    <van-tab @change="changeTab" title="取消"></van-tab>
                </van-tabs>
            </div>
            <div class="cellcontent margin32">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="暂无数据"
                            @load="onLoad"
                    >
                        <div
                                v-if="logList.length>0"
                                class="cell marginbottom24"
                                v-for="item in logList"
                                :key="item.id"
                        >
                            <div class="top">
                                <div
                                        @click="clickOrderNo(item, 'orderBtn')"
                                        class="title van-ellipsis"
                                >
                                    {{ item.entrust_no }}
                                </div>
                                <div class="status">{{ getTransferTxt(item.status) }}</div>
                            </div>
                            <div class="money margin16">
                                <div class="price">
                                    <div class="num">{{ parseFloat(item.price).toFixed(3) }}</div>
                                    <div class="pricetype margin8">订单价格(USD)</div>
                                </div>
                                <div class="price">
                                    <div class="num">
                                        {{ parseFloat(item.total_price).toFixed(2) }}
                                    </div>
                                    <div class="pricetype margin8">支付金额(CNY)</div>
                                </div>
                                <div class="price">
                                    <div class="num">
                                        {{ parseFloat(item.total_num).toFixed(2) }}
                                    </div>
                                    <div class="pricetype margin8">
                                        订单数量：({{ item.stock_code }})
                                    </div>
                                </div>
                            </div>
                            <div class="card margin16" v-if="item.status == 1">
                                <div class="time">{{ item.pay_time }}</div>
                                <div class="count">
                                    支付倒计时:
                                    <van-count-down
                                            :time="getTimeDiff(item.create_time, item.pay_waiting)"
                                            format=" mm 分 ss 秒"
                                    />
                                </div>
                            </div>
                            <div class="way margin24">
                                <div class="method">
                                    支付方式：
                                    <div
                                            class="payway"
                                            v-for="pay in item.pay_type.split(',')"
                                            :key="pay"
                                    >
                                        <img
                                                v-if="pay === '1'"
                                                class="payicon"
                                                @click="clickViewPay('1', item, 'ViewPay')"
                                                :src="require('@/assets/images/mine/usdt.png')"
                                        />
                                        <img
                                                v-if="pay === '2'"
                                                @click="clickViewPay('2', item, 'ViewPay')"
                                                class="payicon"
                                                :src="require('@/assets/images/mine/zhifubao.png')"
                                        />
                                        <img
                                                v-if="pay === '3'"
                                                @click="clickViewPay('3', item, 'ViewPay')"
                                                class="payicon"
                                                :src="require('@/assets/images/mine/card.png')"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="way margin24">
                                <div
                                        @click="viewScreen(item, 'viewScreen')"
                                        class="operation"
                                        v-if="
                    item.status == 2 || item.status == 3 || item.status == 4
                  "
                                >
                                    查看支付截图
                                </div>
                                <div class="operation">
                                    <div
                                            v-if="item.status == 1 && item.buy_address.toLowerCase() == address.toLowerCase()"
                                            class="operate"
                                            @click="uploadPayShow(item)"
                                    >
                                        <!--@click="uploadPay(item, 'uploadPay')"-->
                                        上传支付
                                    </div>
                                    <div
                                            v-if="item.status == 1 && item.buy_address.toLowerCase() == address.toLowerCase() && item.pay_waiting==0"
                                            class="operate"
                                            @click="clickRelease(item, 'paywite')"
                                    >
                                        支付延时
                                    </div>
                                    <div
                                            v-if="item.status == 1 && item.buy_address.toLowerCase() == address.toLowerCase() && item.pay_waiting==1"
                                            class="operate"
                                    >
                                        支付已延时
                                    </div>
                                    <div
                                            v-if="item.status == 2 && item.sell_address.toLowerCase() == address.toLowerCase()"
                                            class="operate"
                                            @click="clickRelease(item, 'clickRelease')"
                                    >
                                        确认放行
                                    </div>
                                    <div
                                            v-if="item.remark_info.length == 0 && (item.status == 2 &&  item.sell_address.toLowerCase() == address.toLowerCase())"
                                            class="operate"
                                            @click="appealShow(item)"

                                    >
                                        <!--@click="clickApply(item, 'clickApply')"-->
                                        申述
                                    </div>
                                    <div
                                            class="operate"
                                            v-if="item.status == 4 || item.remark_info.length > 0"
                                            @click="clickViewApply(item, 'ViewApply')"
                                    >
                                        申述结果
                                    </div>
                                </div>
                            </div>
                            <div class="remark margin16" v-if="item.remark_info.length > 0">
                                <div class="apply">申诉原因：{{ item.remark_info }}</div>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
        <Screenshots ref="screenshot" @refreshList="refreshList"></Screenshots>
        <Complaint ref="complaint" @refreshList="refreshList"></Complaint>
        <Viewpay ref="viewpay"></Viewpay>
        <Payscreen ref="payscreen"></Payscreen>
        <Applytext ref="applytext"></Applytext>
        <OrderDetail
                ref="orderDetail"
                @sendViewPay="sendViewPay"
                @sendViewApply="sendViewApply"
                @sendApply="sendApply"
                @sendRelease="sendRelease"
                @senduploadPay="senduploadPay"
                @sendviewScreen="sendviewScreen"
        ></OrderDetail>
        <van-dialog
                v-model="showpwd"
                title="请输入安全码"
                @cancel="showpwd = false"
                @confirm="confirm"
                show-cancel-button
        >
            <van-field
                    type="password"
                    v-model="password"
                    name="安全密码"
                    label="安全密码"
                    placeholder="请输入安全密码"
            ></van-field>
        </van-dialog>
    </div>
</template>

<script>
    import {myRecordLog, confirmRelease, viewAppeal, viewPayment, viewSellPayWay,viewOrderDetail,waitOrder} from "@/request/api";
    import Header from "../../components/header.vue";
    import Complaint from "./modal/complaint.vue";
    import Screenshots from "./modal/screenshots.vue";
    import {Toast} from "vant";
    import Viewpay from "./modal/viewpay.vue";
    import Payscreen from "./modal/payscreen.vue";
    import Applytext from "./modal/applytext.vue";
    import OrderDetail from "./modal/orderDetail.vue";

    export default {
        components: {
            Header,
            Screenshots,
            Complaint,
            Viewpay,
            Payscreen,
            Applytext,
            OrderDetail,
        },
        computed: {
            getTransferTxt() {
                return (val) => {
                    let txt = "";
                    switch (val) {
                        case 1:
                            txt = "待付款";
                            break;
                        case 2:
                            txt = "已付款";
                            break;
                        case 3:
                            txt = "已完成";
                            break;
                        case 4:
                            txt = "申述中";
                            break;
                        case 5:
                            txt = "取消";
                            break;
                    }
                    return txt;
                };
            },
        },
        data() {
            return {
                paytype: "",
                clickName: "",
                showpwd: false,
                tmpItem: {},
                password: "",
                userInfo: {},
                type: 1,
                activeTab: 0,
                coin_symbol: "ATT",
                address: "",
                logList: [],
                currentpage: 1,
                refreshing: false,
                loading: false,
                finished: false,
                remarkInfo: '',//申诉信息
                imgUrl: '', //支付图片
                payOrderInfo: {},
            };
        },
        activated() {
            this.coin_symbol = this.$route.query.coin;
            this.userInfo = sessionStorage.getItem("user")
                ? JSON.parse(sessionStorage.getItem("user"))
                : {};
            this.address = sessionStorage.getItem("address");
            // this.address = "0xfb05b784a0fd303056f3a3b5589595d31b7588e0";
            if(this.address &&sessionStorage.getItem(this.address)){
                this.getOrderLog();
            }
        },
        methods: {
            witePay(){  //交易延时
                const params = {
                    id: this.tmpItem.id,
                    pay_code: this.password,
                    address: this.address,
                };
                waitOrder(params).then((res) => {
                    if (res.code == 200) {
                        Toast(res.msg);
                    } else {
                        Toast(res.msg);
                    }
                });
            },
            getOrderDetaill() {
                const params = {
                    id:  this.tmpItem.id,
                    pay_code: this.password,
                    address: this.address,
                };
                viewOrderDetail(params).then((res) => {
                    if (res.code==200) {
                        this.orderInfo = res.data;
                        this.$refs.orderDetail.preview(this.orderInfo);
                    } else {
                        Toast(res.msg);
                        this.show = false;
                    }
                });
            },
            uploadPayShow(item){
                this.tmpItem = item;
                this.$refs.screenshot.showScreen(this.tmpItem);
            },
            appealShow(item) {
                this.tmpItem = item;
                this.$refs.complaint.showComplaint(
                    this.tmpItem,
                );
            },
            //查看支付方式
            getviewSellPayWay() {
                const params = {
                    id: this.tmpItem.id,
                    pay_type: this.paytype,
                    pay_code: this.password,
                    address: this.address,
                };
                viewSellPayWay(params).then((res) => {
                    if (res.code == 200) {
                        this.payOrderInfo = res.data;
                        this.$refs.viewpay.preview(this.payOrderInfo);
                    } else {
                        Toast(res.msg);
                    }
                });
            },
            //查看支付信息
            getviewPayment() {
                const params = {
                    id: this.tmpItem.id,
                    pay_code: this.password,
                    address: this.address,
                };
                viewPayment(params).then((res) => {
                    if (res.code == 200) {
                        this.imgUrl = res.data;
                        this.$refs.payscreen.preview(this.imgUrl);
                    } else {
                        Toast(res.msg);
                    }
                });
            },
            //查看申诉结果
            getviewAppeal() {
                const params = {
                    id: this.tmpItem.id,
                    pay_code: this.password,
                    address: this.address,
                };
                viewAppeal(params).then((res) => {
                    if (res.code == 200) {
                        this.remarkInfo = res.data;
                        this.$refs.applytext.preview(this.remarkInfo);
                    } else {
                        Toast(res.msg);
                    }
                });
            },
            getTimeDiff(comtime,payWite) {
                let sTime = new Date(comtime);
                let min = sTime.getMinutes();
                if(payWite==1){
                    sTime = sTime.setMinutes(min + 90);
                }else{
                    sTime = sTime.setMinutes(min + 60);
                }
                let currenttime = new Date();
                let diff = parseInt(sTime - currenttime);
                console.log(diff);
                return diff;
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.refreshing = true;
                this.currentPage = 1;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                if(this.address &&sessionStorage.getItem(this.address)){
                    this.onLoad();
                }
            },
            refreshList() {
                this.onRefresh();
            },
            onLoad() {
                if(this.address &&sessionStorage.getItem(this.address)){
                    this.getOrderLog();
                }
                this.currentpage++;
            },
            clickViewPay(type, item, btnname) {
                this.paytype = type;
                this.password = "";
                this.showpwd = true;
                this.clickName = btnname;
                this.tmpItem = item;
                //   this.$refs.viewpay.preview(type, item);
            },
            sendViewPay(params) {
                this.clickViewPay(params.type, params.item, params.btnname);
            },
            sendViewApply(params) {
                this.clickViewApply(params.item, params.btnname);
            },
            sendApply(params) {
                this.clickApply(params.item, params.btnname);
            },
            sendRelease(params) {
                this.clickRelease(params.item, params.btnname);
            },
            senduploadPay(params) {
                this.uploadPay(params.item, params.btnname);
            },
            sendviewScreen(params) {
                this.viewScreen(params.item, params.btnname);
            },
            viewScreen(item, btnname) {
                this.password = "";
                this.showpwd = true;
                this.clickName = btnname;
                this.tmpItem = item;
            },
            clickOrderNo(item, btnname) {
                this.password = "";
                this.showpwd = true;
                this.clickName = btnname;
                this.tmpItem = item;
            },
            getOrderLog() {

                const params = {
                    coin_symbol: this.coin_symbol,
                    type: this.type,
                    status: this.activeTab,
                    address: this.address,
                    page: this.currentPage,
                    limit: 10,
                };
                if(this.address &&sessionStorage.getItem(this.address)){
                    myRecordLog(params).then((res) => {
                        if (this.refreshing) {
                            this.refreshing = false;
                            this.logList = [];
                        }
                        if (res.success) {
                            this.logList = res.data.list;
                            if (res.data.list.length < 20) {
                                this.finished = true;
                            }
                        } else {
                            Toast(res.msg);
                            this.finished = true;
                        }
                    });
                }
            },
            changeMenu(index) {
                this.type = index;
                this.getOrderLog();
            },
            changeTab() {
                console.log(this.activeTab);
                this.getOrderLog();
            },
            uploadPay(item, btnname) {
                console.log("上传支付");
                this.password = "";
                this.showpwd = true;
                this.tmpItem = item;
                this.clickName = btnname;
                //   this.$refs.screenshot.showScreen(item);
            },
            clickApply(item, btnname) {
                this.password = "";
                this.showpwd = true;
                this.tmpItem = item;
                this.clickName = btnname;
                //   this.$refs.complaint.showComplaint(item, this.type);
            },
            clickViewApply(item, btnname) {
                this.password = "";
                this.showpwd = true;
                this.tmpItem = item;
                this.clickName = btnname;
            },
            confirm() {
                if (this.password.length === 0) {
                    Toast("请输入安全码");
                    return;
                }
                if (this.clickName == "clickRelease") {
                    this.confirmRelease();
                } else if (this.clickName == "uploadPay") {
                    this.$refs.screenshot.showScreen(this.tmpItem, this.password);
                } else if (this.clickName == "clickApply") {
                    this.$refs.complaint.showComplaint(
                        this.tmpItem,
                        this.type,
                        this.password
                    );
                } else if (this.clickName == "viewScreen") {
                    this.getviewPayment();
                } else if (this.clickName == "ViewPay") {
                    this.getviewSellPayWay()


                } else if (this.clickName == "ViewApply") {
                    this.getviewAppeal();

                } else if (this.clickName == "orderBtn") {
                    this.getOrderDetaill();
                }else if(this.clickName == 'paywite'){
                    this.witePay();
                }
            },
            confirmRelease() {
                const params = {
                    id: this.tmpItem.id,
                    pay_code: this.password,
                    address: this.address,
                };
                confirmRelease(params).then((res) => {
                    if (res.code == 200) {
                        Toast("操作成功");
                        this.onRefresh();
                    } else {
                        Toast(res.msg);

                    }
                });
            },
            clickRelease(item, btnname) {
                this.password = "";
                this.showpwd = true;
                this.tmpItem = item;
                this.clickName = btnname;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .margin36 {
        margin-top: 36px;
    }

    .margin32 {
        margin-top: 32px;
    }

    .margin16 {
        margin-top: 16px;
    }

    .margin8 {
        margin-top: 8px;
    }

    .margin24 {
        margin-top: 24px;
    }

    .marginbottom24 {
        margin-bottom: 24px;
    }

    .bgcontent {
        background: rgba(13, 25, 18, 1);
        min-height: 100vh;
        .order {
            padding: 32px;
            .menubtn {
                margin-top: 40px;
                display: flex;
                justify-content: center;
                .btn {
                    display: flex;
                    width: 320px;
                    height: 72px;
                    background: rgba(32, 40, 35, 1);
                    border-radius: 24px;
                    .getbtn {
                        width: 160px;
                        height: 72px;
                        border-radius: 24px;
                        opacity: 1;
                        background: rgba(39, 204, 127, 1);
                        color: rgba(13, 25, 18, 1);
                        font-size: 32px;
                        font-weight: 700;
                        font-family: "PingFang SC";
                        text-align: center;
                        line-height: 72px;
                    }
                    .consumptionbtn {
                        width: 160px;
                        height: 72px;
                        border-radius: 24px;
                        opacity: 1;
                        color: rgba(182, 217, 195, 1);
                        font-size: 32px;
                        font-weight: 700;
                        font-family: "PingFang SC";
                        text-align: center;
                        line-height: 72px;
                    }
                }
            }
            .cellcontent {
                .cell {
                    width: 686px;
                    min-height: 276px;
                    border-radius: 24px;
                    opacity: 1;
                    background: rgba(32, 40, 35, 1);
                    padding: 24px;
                    .top {
                        display: flex;
                        justify-content: space-between;
                        .title {
                            color: rgba(182, 217, 195, 1);
                            font-size: 24px;
                            font-weight: 400;
                            font-family: "PingFang SC";
                            text-align: center;
                            line-height: 44px;
                        }
                        .status {
                            width: 112px;
                            height: 44px;
                            border-radius: 16px 0 0 16px;
                            background: rgba(39, 199, 204, 0.2);
                            color: rgba(39, 199, 204, 1);
                            font-size: 24px;
                            font-weight: 400;
                            font-family: "PingFang SC";
                            text-align: center;
                            line-height: 44px;
                        }
                    }
                    .money {
                        display: flex;
                        justify-content: space-between;
                        .price {
                            .num {
                                opacity: 1;
                                color: rgba(214, 255, 227, 1);
                                font-size: 44px;
                                font-weight: 500;
                                font-family: "DIN";
                            }
                            .pricetype {
                                color: rgba(139, 166, 149, 1);
                                font-size: 24px;
                                font-weight: 400;
                                font-family: "PingFang SC";
                            }
                        }
                    }
                    .card {
                        width: 638px;
                        height: 64px;
                        border-radius: 16px;
                        opacity: 1;
                        background: rgba(41, 51, 45, 1);
                        display: flex;
                        justify-content: space-between;
                        padding: 0 16px;
                        .time {
                            opacity: 1;
                            color: rgba(139, 166, 149, 1);
                            font-size: 24px;
                            font-weight: 400;
                            font-family: "PingFang SC";
                            line-height: 64px;
                        }
                        .count {
                            opacity: 1;
                            color: rgba(139, 166, 149, 1);
                            line-height: 64px;
                            font-weight: 400;
                            font-family: "PingFang SC";
                            display: flex;
                            ::v-deep .van-count-down {
                                color: rgba(139, 166, 149, 1);
                                font-size: 12px;
                                line-height: 32px;
                            }
                        }
                    }
                    .way {
                        color: rgba(139, 166, 149, 1);
                        font-size: 28px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                        text-align: left;
                        line-height: 40px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .method {
                            display: flex;
                            align-items: center;
                            .payway {
                                display: flex;
                                align-items: center;
                                .payicon {
                                    margin-right: 10px;
                                    width: 32px;
                                    height: 32px;
                                }
                            }
                        }
                        .operation {
                            display: flex;
                            align-items: center;
                            .uploadicon {
                                width: 32px;
                                height: 32px;
                            }
                            .operate {
                                border-radius: 16px;
                                opacity: 1;
                                background: rgba(214, 255, 227, 1);
                                padding: 10px;
                                color: rgba(39, 204, 127, 1);
                                font-size: 26px;
                                font-weight: 700;
                                font-family: "PingFang SC";
                                margin-left: 5px;
                            }
                        }
                    }
                    .remark {
                        .apply {
                            font-size: 28px;
                            font-weight: 400;
                            font-family: "PingFang SC";
                            text-align: left;
                            line-height: 40px;
                            color: rgba(139, 166, 149, 1);
                        }
                    }
                }
            }
        }
    }
</style>
<template>
  <div>
    <van-action-sheet v-model="showView" title="申诉" class="modalclass">
      <div class="complaint">
        <div class="uploadcontent"   v-if="showUpload" >
          <van-uploader :max-size="5000 * 1024" :after-read="clickUpload">
            <img
                    v-if="imgurl.length == 0"
                    class="uploadicon"   style="width: 150px;height: 150px;"
                    :src="require('@/assets/images/mine/uploadimg.png')"
            />
            <img v-else class="uploadicon" :src="imgurl" style="width: 150px;height: 150px;" />
          </van-uploader>
          <div class="txt">请上传您的收款截图</div>
        </div>
        <div class="problem">
          <van-field
            class="inputClass margin32"
            v-model="message"
            type="textarea"
            maxlength="50"
            placeholder="问题描述"
            show-word-limit
          />
        </div>
        <div class="storebtn margin48">
          <div class="btn" @click="showpwd1 = true">保存</div>
        </div>
        <div class="tip margin32">
          注意 <br />
          1.请先支付后保留支付凭证 <br />
          2.上传凭证等待确认
        </div>
      </div>
    </van-action-sheet>
    <van-dialog
            v-model="showpwd1"
            title="请输入安全码"
            @cancel="showpwd1 = false"
            @confirm="clickstore"
            show-cancel-button
    >
      <van-field
              type="password"
              v-model="password"
              name="安全密码"
              label="安全密码"
              placeholder="请输入安全密码"
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
import { uploadImg,appealPayment } from "@/request/api";

import { Toast } from "vant";
export default {
  components: {
    // Header
  },
  data() {
    return {
      showUpload: false,
      imgurl: "",
      showView: false,
      message: "",
      oid: "",
      type: "",
      userInfo: {},
      address: "",
      password: "",
        showpwd1:false,
        applyOrder:{},
    };
  },
  methods: {
      clickUpload(file) {
          const fd = new FormData();
          fd.append("file", file.file);
          uploadImg(fd).then((res) => {
              if(res.code==200){
                  this.imgurl = res.data.http_url;
              }else{
                  Toast(res.msg)
              }

              // this.uploadScreen();
          });
      },
    showComplaint(item) {
      this.userInfo = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : {};
      this.address = sessionStorage.getItem("address");
      this.applyOrder = item;
      this.showView = true;
        this.oid = item.id;
        this.type = item.type;
        if(this.address.toLowerCase() == item.sell_address.toLowerCase()){
            this.showUpload = true;
        }
    },
    clickstore() {
      if (this.message.length === 0) {
        Toast("请输入申诉内容");
        return;
      }
        if (this.password.length === 0) {
            Toast("请输入安全码");
            return;
        }
      const params = {
        id: this.oid,
        address: this.address,
        remark_info: this.message,
        pay_code: this.password,
        type: this.type,
          back_url: this.imgurl,
      };
      appealPayment(params).then((res) => {
        if (res.code==200) {
          Toast("申诉成功");
          this.showView = false;
          this.$emit("refreshList");
        } else {
          Toast(res.msg);
            this.showView = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.margin48 {
  margin-top: 48px;
}
.margin32 {
  margin-top: 32px;
}
.modalclass {
  background: #202823;
  border-radius: 12px 12px 0px 0px;
  color: #f5fff9;
  text-align: center;
  .screen {
    padding: 28px 28px 320px;
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  .imgcontent {
    width: 694px;
    height: 244px;
    border-radius: 16px;
    opacity: 1;
    background: rgba(41, 51, 45, 1);

    .uploadcontent {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .uploadicon {
        width: 150px;
        height: 150px;
      }
      .txt {
        opacity: 1;
        color: rgba(139, 166, 149, 1);
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 36px;
      }
    }
    .surebtn {
      display: flex;
      justify-content: center;
      .btn {
        width: 320px;
        height: 88px;
        border-radius: 16px;
        opacity: 1;
        background: rgba(39, 204, 127, 1);
        color: rgba(32, 40, 35, 1);
        font-size: 36px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 88px;
      }
    }
  }
  .tip {
    margin-top: 32px;
    opacity: 1;
    color: rgba(182, 217, 195, 1);
    font-size: 24px;
    font-weight: 700;
    line-height: 44px;
  }
}
.modalclass {
  background: #202823;
  border-radius: 12px 12px 0px 0px;
  color: #f5fff9;
  .complaint {
    padding: 32px 32px 80px;

  }
  .problem {
    width: 694px;
    // height: 244px;
    border-radius: 16px;
    opacity: 1;
    background: rgba(41, 51, 45, 1);
    .inputClass {
      background: #29332d;

      ::v-deep .van-field__control {
        color: white;
        height: 80px;
      }
    }
  }
  .storebtn {
    display: flex;
    justify-content: center;
    .btn {
      width: 320px;
      height: 88px;
      border-radius: 16px;
      opacity: 1;
      background: rgba(39, 204, 127, 1);
      color: rgba(32, 40, 35, 1);
      font-size: 36px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 88px;
    }
  }
  .tip {
    margin-top: 32px;
    opacity: 1;
    color: rgba(182, 217, 195, 1);
    font-size: 24px;
    font-weight: 700;
    line-height: 44px;
    text-align: left;
  }
}
</style>
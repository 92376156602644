<template>
  <div>
    <van-popup class="popclass" closeable v-model="show">
      <div class="content">
        <div class="title">支付截图</div>
        <van-image width="300" height="300" :src="imgurl" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { viewPayment } from "@/request/api";
import { Toast } from "vant";
export default {
  data() {
    return {
      show: false,
      oid: "",
      userInfo: {},
      password: "",
      address: "",
        imgurl:''
    };
  },
  methods: {
    preview(item) {
      this.imgurl = item;
      this.userInfo = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : {};
      this.show = true;
      this.address = sessionStorage.getItem("address");

    },
    getviewPayment() {
      const params = {
        id: this.oid,
        pay_code: this.password,
        address: this.address,
      };
      viewPayment(params).then((res) => {
        if (res.code==200) {
          this.imgurl = res.data;
        } else {
          Toast(res.msg);
            this.show = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 32px;

  .title {
    color: rgba(139, 166, 149, 1);
    font-size: 28px;
    font-weight: 400;
    font-family: "PingFang SC";
    margin-bottom: 18px;
  }
}
</style>
<template>
    <div>
        <van-popup class="popclass" closeable v-model="show">
            <div class="content">
                <div class="title">订单详情</div>
                <div class="detail">
                    <div class="van-multi-ellipsis--l2">
                        订单编号: {{ orderInfo.entrust_no }}
                    </div>
                    <div class="margin15">
                        状态: {{ getTransferTxt(orderInfo.status) }}
                    </div>
                    <div class="margin15">
                        订单价格(USD): {{ parseFloat(orderInfo.price).toFixed(2) }}
                    </div>
                    <div class="margin15">
                        订单金额(CNY): {{ parseFloat(orderInfo.total_price).toFixed(2) }}
                    </div>
                    <div class="margin15">
                       订单金额(USD): {{ (parseFloat(orderInfo.total_num)*parseFloat(orderInfo.price)).toFixed(2) }}
                    </div>
                    <div class="margin15">
                        订单数量({{ orderInfo.stock_code }}):
                        {{ parseFloat(orderInfo.total_num).toFixed(2) }}
                    </div>
                    <div class="way margin15" v-if="orderInfo.buy_address.toLowerCase() == address.toLowerCase()">
                        <span>卖家昵称:{{ otherInfo.name }} </span>
                        <div class="button" v-if="orderInfo.stock_code=='USDT'" @click="telOther(otherInfo.mobile)">电话:{{ otherInfo.mobile }}</div>
                    </div>
                    <div class="way margin15" v-if="orderInfo.sell_address.toLowerCase() == address.toLowerCase()">
                        <span>买家昵称:{{ otherInfo.name }} </span>
                        <div class="button"  @click="telOther(otherInfo.mobile)">电话:{{ otherInfo.mobile }}</div>
                    </div>
                    <div class="way margin15" v-if="orderInfo.images_url">
                        <div>支付截图</div>
                    </div>
                    <div class="way margin15" v-if="orderInfo.images_url">
                        <van-image width="200" height="200" :src="orderInfo.images_url" />
                    </div>
                    <div class="margin15" v-if="orderInfo.remark_info.length > 0">
                        申诉原因：{{ orderInfo.remark_info }}
                    </div>
                    <div class="popclass" v-for="(payInfo,index) in orderInfo.payInfo">
                        <div class="title" v-if="payInfo.pay_type == 1">USDT</div>
                        <div class="title" v-if="payInfo.pay_type == 2">支付宝</div>
                        <div class="title" v-if="payInfo.pay_type == 3">银行卡</div>
                        <div class="content">
                            <div>姓名：{{ payInfo.username }}</div>
                            <div v-if="payInfo.pay_type == 1"  @click="copyLink1(payInfo.wx_account)" id="tag1">地址：
                                <a href="javascript:;">{{ payInfo.wx_account }}</a>
                            </div>
                            <div v-if="payInfo.pay_type == 2" @click="copyLink2(payInfo.alipay_account)" id="tag2">支付宝账号:
                                <a href="javascript:;">
                                    {{ payInfo.alipay_account }}
                                </a>
                            </div>
                            <div v-if="payInfo.pay_type == 1">
                                <div>收款二维码:</div>
                                <van-image width="180" height="180" :src="payInfo.wx_img||'https://img01.yzcdn.cn/vant/leaf.jpg'" style="margin-top: 15px;"/>
                            </div>
                            <div v-if="payInfo.pay_type == 2">
                                <div>收款二维码:</div>
                                <van-image width="180" height="180" :src="payInfo.ali_img||'https://img01.yzcdn.cn/vant/leaf.jpg'" style="margin-top: 15px;"/>
                            </div>
                            <template v-if="payInfo.pay_type==3">
                                <div @click="copyLink3(payInfo.bank_card_no)" id="tag3">银行卡号:
                                    <a href="javascript:;">
                                        {{ payInfo.bank_card_no }}
                                    </a>
                                </div>
                                <div>开户支行:{{ payInfo.bank_card_union_no }}</div>
                                <div>银行卡类型:{{ payInfo.bank_card_type }}</div>
                                <div>开户行:{{ payInfo.bank_card_open_bank }}</div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import Clipboard from 'clipboard'
    import {viewOrderDetail} from "@/request/api";
    import {Toast} from "vant";

    export default {
        data() {
            return {
                show: false,
                oid: "",
                userInfo: {},
                remarkInfo: "",
                password: "",
                address: "",
                orderInfo: {
                    pay_type: "",
                    remark_info: "",
                },
                otherInfo:{},
                payList:[],
            };
        },
        computed: {
            getTransferTxt() {
                return (val) => {
                    let txt = "";
                    switch (val) {
                        case 1:
                            txt = "待付款";
                            break;
                        case 2:
                            txt = "已付款";
                            break;
                        case 3:
                            txt = "已完成";
                            break;
                        case 4:
                            txt = "申述中";
                            break;
                        case 5:
                            txt = "取消";
                            break;
                    }
                    return txt;
                };
            },
        },
        methods: {
            copyLink1(content){
                var _this = this;
                let clipboard = new Clipboard('#tag1', {
                    text: function () {
                        return content;
                    }
                })
                clipboard.on('success', e => {
                    Toast('复制成功');
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    Toast('复制失败');
                    clipboard.destroy()
                })
            },
            copyLink2(content){
                var _this = this;
                let clipboard = new Clipboard('#tag2', {
                    text: function () {
                        return content;
                    }
                })
                clipboard.on('success', e => {
                    Toast('复制成功');
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    Toast('复制失败');
                    clipboard.destroy()
                })
            },
            copyLink3(content){
                var _this = this;
                let clipboard = new Clipboard('#tag3', {
                    text: function () {
                        return content;
                    }
                })
                clipboard.on('success', e => {
                    Toast('复制成功');
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    Toast('复制失败');
                    clipboard.destroy()
                })
            },
            telOther(mobile){
                Toast('联系对方请拨打:'+mobile);
            },
            clickViewApply(item, btnname) {
                const params = {
                    item: item,
                    btnname: btnname,
                };
                this.$emit("sendViewApply", params);
            },
            clickApply(item, btnname) {
                const params = {
                    item: item,
                    btnname: btnname,
                };
                this.$emit("sendApply", params);
            },
            clickRelease(item, btnname) {
                const params = {
                    item: item,
                    btnname: btnname,
                };
                this.$emit("sendRelease", params);
            },
            uploadPay(item, btnname) {
                const params = {
                    item: item,
                    btnname: btnname,
                };
                this.$emit("senduploadPay", params);
            },
            viewScreen(item, btnname) {
                const params = {
                    item: item,
                    btnname: btnname,
                };
                this.$emit("sendviewScreen", params);
            },
            clickViewPay(type, item, btnname) {
                const params = {
                    type: type,
                    item: item,
                    btnname: btnname,
                };
                this.$emit("sendViewPay", params);
            },
            preview(item) {
                this.orderInfo = item;
                this.otherInfo = item.userInfo;
                this.payList = item.payInfo;
                console.log(this.otherInfo)
                console.log(this.orderInfo)
                this.userInfo = sessionStorage.getItem("user")
                    ? JSON.parse(sessionStorage.getItem("user"))
                    : {};
                this.show = true;
                this.address = sessionStorage.getItem("address");
            },
            getOrderDetaill() {
                const params = {
                    id: this.oid,
                    pay_code: this.password,
                    address: this.address,
                };
                viewOrderDetail(params).then((res) => {
                    if (res.code == 200) {
                        console.log(res.data);
                        this.orderInfo = res.data;
                    } else {
                        Toast(res.msg);
                        this.show = false;
                    }
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .popclass {
        width: 640px;
        height: 900px;
        background: #0d1912;
        .title{
            font-size: 36px;
            text-align: center;
            font-weight: bold;
            height: 50px;
            line-height: 50px;
            color: #fff;
            margin-top: 30px;
        }
        div{
            color: #37C085;
        }
        .content {
            padding: 32px;
            color: white;
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
            line-height: 50px;
            word-break:break-word;
            a{
                color: #6FB4EB;
            }
        }
    }
    .margin15 {
        margin-top: 15px;
        color: #45A787;
    }

    .popclass {
        width: 640px;
        height: 900px;
        background: #0d1912;
        color: #45A787;
    }

    .content {
        color: #45A787;
        padding: 32px;

        .title {
            color: #37C085;
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
            margin-bottom: 18px;
            text-align: center;
        }
        div{
            color: #37C085;
        }
        .detail {
            min-width: 600px;
            min-height: 800px;
            color: white;
            font-size: 28px;
            .way {
                color: #45A787;
                font-size: 28px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: left;
                line-height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .method {
                    display: flex;
                    align-items: center;
                    .payway {
                        display: flex;
                        align-items: center;
                        .payicon {
                            margin-right: 10px;
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
                .operation {
                    display: flex;
                    align-items: center;
                    .uploadicon {
                        width: 32px;
                        height: 32px;
                    }
                    .operate {
                        border-radius: 16px;
                        opacity: 1;
                        background: rgba(214, 255, 227, 1);
                        padding: 10px;
                        color: rgba(39, 204, 127, 1);
                        font-size: 26px;
                        font-weight: 700;
                        font-family: "PingFang SC";
                        margin-left: 5px;
                    }
                }
            }
        }
        .button{
            border-radius: 0.21333rem;
            opacity: 1;
            background: #d6ffe3;
            padding: 0.13333rem;
            color: #27cc7f;
            font-size: 0.34667rem;
            font-weight: 700;
            font-family: "PingFang SC";
            margin-left: 0.06667rem;
        }

    }
</style>